import(/* webpackMode: "eager" */ "/home/runner/work/web-apps/web-apps/node_modules/.pnpm/next@14.2.10_@babel+core@7.23.7_babel-plugin-macros@3.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-apps/web-apps/node_modules/.pnpm/next@14.2.10_@babel+core@7.23.7_babel-plugin-macros@3.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-apps/web-apps/node_modules/.pnpm/next@14.2.10_@babel+core@7.23.7_babel-plugin-macros@3.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-apps/web-apps/node_modules/.pnpm/next@14.2.10_@babel+core@7.23.7_babel-plugin-macros@3.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-apps/web-apps/node_modules/.pnpm/next@14.2.10_@babel+core@7.23.7_babel-plugin-macros@3.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-apps/web-apps/node_modules/.pnpm/next@14.2.10_@babel+core@7.23.7_babel-plugin-macros@3.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/components/render-from-template-context.js");
